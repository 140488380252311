.progress-bar {
  position: relative;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(2, auto);
  grid-template-areas:
    "top-start top-end"
    "middle middle"
    "bottom-start bottom-end";

  &__container {
    position: relative;
    overflow: hidden;
    background: var(--progress-bar-indicator-background, silver);
    grid-area: middle;
  }

  &__indicator_paper {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    height: var(--progress-bar-height, 10px);

    overflow: hidden;

    grid-area: middle;

    background: var(--progress-bar-paper, silver);

    [dir="rtl"] & {
      transform: scaleX(-1);
    }
  }

  &__indicator__mask {
    position: absolute;
    top: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;
  }

  &__indicator_ink {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    padding-right: 100%;

    background: var(--progress-bar-ink, red);
  }

  &__slot {
    display: flex;

    &[data-progress-bar-slot-name="top-start"] {
      grid-area: top-start;
    }

    &[data-progress-bar-slot-name="top-end"] {
      grid-area: top-end;
    }

    &[data-progress-bar-slot-name="bottom-start"] {
      grid-area: bottom-start;
    }

    &[data-progress-bar-slot-name="bottom-end"] {
      grid-area: bottom-end;
    }

    &[data-progress-bar-slot-name$="-end"] {
      justify-content: end;
    }
  }
}
